// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-indiana-jones-tsx": () => import("./../../../src/pages/indiana-jones.tsx" /* webpackChunkName: "component---src-pages-indiana-jones-tsx" */),
  "component---src-pages-newsletter-tsx": () => import("./../../../src/pages/newsletter.tsx" /* webpackChunkName: "component---src-pages-newsletter-tsx" */),
  "component---src-pages-stripes-tsx": () => import("./../../../src/pages/stripes.tsx" /* webpackChunkName: "component---src-pages-stripes-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-youtube-post-tsx": () => import("./../../../src/templates/youtube-post.tsx" /* webpackChunkName: "component---src-templates-youtube-post-tsx" */)
}

